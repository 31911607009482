import { LogLevel } from '@wellro/utils';

export const environment = {
  production: true,
  whatsAppNumber: '+94705302302',
  firebase: {
    apiKey: 'AIzaSyANykIkIsUPn4L_bo3zPfNS8f9o4Dq8Kj4',
    authDomain: 'wellro-wellness-preprod.firebaseapp.com',
    projectId: 'wellro-wellness-preprod',
    storageBucket: 'wellro-wellness-preprod.appspot.com',
    messagingSenderId: '313313290513',
    appId: '1:313313290513:web:a30e52de92fe4266d384b0',
    measurementId: 'G-7GBBF8XE92',
  },
  sentry: {
    dsn:
      'https://f2a5d616b5204c70bb4a8e66f3c62bd3@o4504682974740480.ingest.sentry.io/4504693759279104',
    env: 'pre-production',
  },
  tenantId: 'customer-1ewfb',
  logLevel: LogLevel.WARNS,
  hostUrl: 'https://preprod.wellro.life',
};
